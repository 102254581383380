export default {
  name: 'Tên',
  tel: 'Điện thoại',
  save: 'Cứu',
  confirm: 'Xác nhận',
  cancel: 'Hủy bỏ',
  delete: 'Xóa bỏ',
  complete: 'Hoàn chỉnh',
  loading: 'Đang tải...',
  telEmpty: 'Vui lòng điền vào điện thoại',
  nameEmpty: 'Vui lòng điền tên',
  nameInvalid: 'Tên không đúng định dạng.',
  confirmDelete: 'Bạn có chắc chắn muốn xóa?',
  telInvalid: 'Số điện thoại không đúng định dạng',
  vanCalendar: {
    end: 'Chấm dứt',
    start: 'Khởi đầu',
    title: 'Lịch',
    startEnd: 'Bắt đầu / Kết thúc.',
    weekdays: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    monthTitle: function monthTitle(year, month) {
      return year + '/' + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return 'Chọn không nhiều hơn ' + maxRange + ' Ngày';
    },
  },
  vanCascader: {
    select: 'Lựa chọn',
  },
  vanContactCard: {
    addText: 'Thêm thông tin liên lạc',
  },
  vanContactList: {
    addText: 'Thêm liên lạc mới',
  },
  vanPagination: {
    prev: 'Trước',
    next: 'Kế tiếp',
  },
  vanPullRefresh: {
    pulling: 'Kéo để làm mới...',
    loosing: 'Lỏng để làm mới ...',
  },
  vanSubmitBar: {
    label: 'Tổng cộng:',
  },
  vanCoupon: {
    unlimited: 'Vô hạn',
    discount: function discount(_discount) {
      return _discount * 10 + '% off';
    },
    condition: function condition(_condition) {
      return 'Ít nhất ' + _condition;
    },
  },
  vanCouponCell: {
    title: 'Phiếu mua hàng',
    tips: 'Không có phiếu giảm giá',
    count: function count(_count) {
      return 'Bạn có ' + _count + ' phiếu giảm giá';
    },
  },
  vanCouponList: {
    empty: 'Không có phiếu giảm giá',
    exchange: 'Đổi',
    close: 'Đóng',
    enable: 'Có sẵn',
    disabled: 'Không có sẵn',
    placeholder: 'Mã giảm giá',
  },
  vanAddressEdit: {
    area: 'Diện tích',
    postal: 'Thuộc về bưu điện',
    areaEmpty: 'Vui lòng chọn một khu vực tiếp nhận',
    addressEmpty: 'Địa chỉ không thể trống',
    postalEmpty: 'Mã bưu chính sai',
    defaultAddress: 'Đặt làm địa chỉ mặc định',
    telPlaceholder: 'Điện thoại',
    namePlaceholder: 'Tên',
    areaPlaceholder: 'Diện tích',
  },
  vanAddressEditDetail: {
    label: 'Địa chỉ nhà',
    placeholder: 'Địa chỉ nhà',
  },
  vanAddressList: {
    add: 'thêm địa chỉ mới',
  },
};
